import { useState } from "react";
import "./chapter.scss";

const Completed = ({ setShowLC }) => {
  const [display, setDisplay] = useState(true);
  return (
    <div className="c_main">
      <div className="c_main_container">
        <div className="c_animation_main">
          <div className="c_star"></div>

          <div className="c_whiteTick">
            <img
              src="https://cdn.pegasus.imarticus.org/courses/images/whiteTick.svg"
              alt=""
              className="whiteTick"
            />
          </div>

          {display && (
            <div
              className="c_bubbles"
              onAnimationEnd={() => {
                setDisplay(false);
              }}
            ></div>
          )}
        </div>

        <div className="c_content_main">
          <div className="c_con_head">Congratulations!</div>
          <div className="c_con_body">
            Great job! <br /> You've completed this chapter! Keep up the <br />
            awesome work.
          </div>

          <button
            className="c_button"
            onClick={() => {
              setShowLC(false);
            }}
          >
            Proceed to Next Chapter
          </button>
        </div>
      </div>
    </div>
  );
};

export default Completed;
